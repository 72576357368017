


















































































import Vue from 'vue';
import dayjs from 'dayjs';

import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputDateTimeCpt',
  props: {
    model: Object,
    prop: String,
    disabled: Boolean,
    dateLabel: {
      type: String,
      required: false,
      default: 'Date',
    },
    timeLabel: {
      type: String,
      required: false,
      default: 'Time',
    },
    type: {
      type: String,
      required: false,
      default: 'datetime',
      validator: function (value) {
        return ['date', 'time', 'datetime'].includes(value);
      },
    },
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  data: () => ({
    dateMenu: false,
    timeMenu: false,
    internalDate: '',
    internalTime: '',
    internalFormattedDate: '',
  }),
  watch: {
    model: {
      handler() {
        this.loadInternalValues();
      },
      deep: true,
      immediate: true,
    },
    prop() {
      this.loadInternalValues();
    },
  },
  created() {
    this.loadInternalValues();
  },
  methods: {
    loadInternalValues() {
      const datetime = (this.model as any)[this.prop as any];

      if (datetime) {
        let parsedDate: dayjs.Dayjs;
        switch (this.type) {
          case 'datetime':
            parsedDate = dayjs(datetime);
            this.internalFormattedDate = parsedDate.format('DD/MM/YYYY');
            this.internalDate = parsedDate.format('YYYY-MM-DD');
            this.internalTime = parsedDate.format('HH:mm');
            break;
          case 'date':
            parsedDate = dayjs(datetime);
            this.internalFormattedDate = parsedDate.format('DD/MM/YYYY');
            this.internalDate = parsedDate.format('YYYY-MM-DD');
            this.internalTime = '';
            break;
          case 'time':
            this.internalTime = datetime;
            this.internalDate = '';
            break;
        }
      }
    },
    onDateChange(value: string) {
      const parsedDate = dayjs(value);
      this.internalFormattedDate = parsedDate.format('DD/MM/YYYY');
      this.internalDate = parsedDate.format('YYYY-MM-DD');

      if (this.type === 'datetime') {
        this.onChange(`${this.internalDate} ${this.internalTime}`);
      } else {
        this.onChange(this.internalDate);
      }
    },
    onTimeChange(value: string) {
      this.internalTime = value;

      if (this.type === 'datetime') {
        this.onChange(`${this.internalDate} ${this.internalTime}`);
      } else {
        this.onChange(this.internalTime);
      }
    },
    onChange(value: any) {
      const parsedDate = dayjs(value);
      this.$props.model[this.$props.prop] = parsedDate.toISOString();
      AppFormComponent.onChange(this, value, this.$props.model, this.$props.prop);
    },
  },
});
