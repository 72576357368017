













































import Vue from 'vue';
import dayjs from 'dayjs';

import { AppFormComponent } from './app-form-component';

export default Vue.extend({
  name: 'InputDateCpt',
  props: {
    model: Object,
    prop: String,
    disabled: Boolean,
    label: {
      type: String,
      required: false,
      default: 'Date',
    },
  },
  computed: {
    errorMessage() {
      return AppFormComponent.errorMessage(this.model, this.prop);
    },
  },
  data: () => ({
    dateMenu: false,
    internalDate: '',
    formattedDate: '',
  }),
  watch: {
    model: {
      handler() {
        this.loadInternalValues();
      },
      deep: true,
      immediate: true,
    },
    prop() {
      this.loadInternalValues();
    },
  },
  created() {
    this.loadInternalValues();
  },
  methods: {
    onTextChanged(value: string) {
      if (value) {
        const parsedDate = dayjs(value, 'DD/MM/YYYY', true);
        if (parsedDate.isValid()) {
          this.internalDate = parsedDate.format('YYYY-MM-DD');
          this.formattedDate = value;
          this.onChange(this.internalDate);
          this.hideMenu();
          return;
        }
      }

      this.internalDate = '';
      this.formattedDate = '';
      this.onChange(this.internalDate);
      this.hideMenu();
    },
    hideMenu() {
      setTimeout(() => {
        this.dateMenu = false;
      }, 300);
    },
    loadInternalValues() {
      const value = (this.model as any)[this.prop as any];
      if (value) {
        const parsedDate = dayjs(value);
        if (parsedDate.isValid()) {
          this.internalDate = parsedDate.format('YYYY-MM-DD');
          this.formattedDate = parsedDate.format('DD/MM/YYYY');
        }
      }
    },
    onDateChange(value: string) {
      if (value) {
        const parsedDate = dayjs(value);
        if (parsedDate.isValid()) {
          this.internalDate = parsedDate.format('YYYY-MM-DD');
          this.formattedDate = parsedDate.format('DD/MM/YYYY');
          this.onChange(this.internalDate);
          this.hideMenu();
          return;
        }
      }

      this.internalDate = '';
      this.formattedDate = '';
      this.onChange(this.internalDate);
    },
    onChange(value: any) {
      if (value) {
        const parsedDate = dayjs(value);
        if (parsedDate.isValid()) {
          this.$props.model[this.$props.prop] = parsedDate.toISOString();
          AppFormComponent.onChange(this, value, this.$props.model, this.$props.prop);
          return;
        }
      }

      this.$props.model[this.$props.prop] = '';
      AppFormComponent.onChange(this, '', this.$props.model, this.$props.prop);
    },
  },
});
